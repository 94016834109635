import React from 'react'
import MainNav from '../../components/Nav/MainNav'
import AddProduct from '../../components/Main/AddProduct'

export default function AddProductPg() {
  return (
   <>
   <MainNav/>
   <div className='container'><AddProduct/>
   </div>
   
   </>
  )
}
